import { FetchedUserSubscription } from "../../features/subscriptions/types";
import { SendRequest } from "../makeSendRequest";
import { User, UserSubscription } from "./userApiTypes";

type EmailPasswordLoginRequest = {
  email: string;
  password: string;
};

type LoginResponse = {
  id: string;
  token: string;
  user: User;
  refreshToken: string;
  migrated?: boolean;
};

type ResetPasswordProps = {
  newPassword: string;
  code: string;
};

export const createUsersApi = (sendRequest: SendRequest) => ({
  logInByEmail: async (loginData: EmailPasswordLoginRequest) => {
    const data = await sendRequest<{ result: LoginResponse }>({
      method: "post",
      path: "users/login",
      data: loginData,
      version: "v2",
    });

    return data?.result;
  },

  requestResetPassword: async (email: string) =>
    sendRequest({
      method: "post",
      path: "users/request_reset_password",
      version: "v2",
      data: { email },
      options: {
        requiresAuth: false,
      },
    }),

  resetPassword: async ({ newPassword, code }: ResetPasswordProps) =>
    sendRequest({
      method: "post",
      path: "users/reset_password",
      version: "v2",
      data: { newPassword, code },
      options: {
        requiresAuth: false,
      },
    }),

  getUserSubscription: async () => {
    const data = await sendRequest<{ user_subscription: UserSubscription }>({
      method: "get",
      path: "usersubscriptions/data",
      version: "v1",
    });
    return data?.user_subscription;
  },

  getUserSubscripions: (userId?: string) =>
    sendRequest<FetchedUserSubscription>({
      method: "get",
      path: "user/get-user-subscriptions",
      version: "v3",
      params: { userId },
    }),

  deleteProfile: async () => {
    const data = await sendRequest<{ success: true }>({
      method: "delete",
      path: "users/profile/delete",
      version: "v2",
    });

    return data;
  },

  updateUser: async (updateData: {
    userId: string;
    roles: string[];
    password: string;
  }) => {
    const data = await sendRequest<{ success: true }>({
      method: "put",
      path: `users/update/${updateData.userId}`,
      version: "v2",
      data: {
        roles: updateData.roles,
        password: updateData.password,
      },
    });

    return data;
  },
});
